<template>
	<div>
		<auth-template>
			<div class="pass-recovery pass-recovery-success" v-if='success'>
				<h1 class="heading-3">{{ $t("auth.password_recovery.dialogs.success.title") }}</h1>
				<p class='pass-recovery__desc'>{{ $t("auth.password_recovery.dialogs.success.title_description") }}</p>
				<v-button color='green white--text' :to="$i18nRoute({name: 'index'})">{{$t('auth.password_recovery.dialogs.success.to_main')}}</v-button>
			</div>
			<div class="pass-recovery" v-else>
				<h1 class="heading-3">{{ $t("auth.password_recovery.title") }}</h1>
				<p class='pass-recovery__desc'>{{ $t("auth.password_recovery.title_description") }}</p>

				<ValidationObserver v-slot="{ invalid }">
					<form class='auth-template-form' @submit.prevent='onSubmit' >
						<ValidationProvider :name='$t("auth.sign_in.email")' rules="required|email" v-slot='{errors}'>
							<v-text-field
								:label='$t("auth.sign_in.email")'
								:placeholder='$t("auth.sign_in.placeholder.email")'
								name='email'
								type='email'
								v-model='email'
								:errorMessage='errors[0]'
								required
							/>
						</ValidationProvider>

						<div class="form-btns">
							<div class="row align-items-center">
								<div class="col-6 pr-0">
									<v-button block color='green white--text' :disabled="invalid" @click='onSubmit' :loading='loading'>{{$t('auth.password_recovery.send')}}</v-button>
								</div>
								<div class="col-6 text-center">
									<router-link class='app-link green--text' :to="$i18nRoute({name: 'sign-in'})">{{$t('auth.sign_up.cancel')}}</router-link>
								</div>
							</div>
						</div>
					</form>
				</ValidationObserver>
			</div>
		</auth-template>
	</div>
</template>

<script>
	import AuthTemplate from '@/views/auth/components/auth-template.vue'
	import { reset } from '@/services/request';

	export default {
		data() {
			return {
				email: '',
				success: false,
				loading: false
			}
		},
		components: {
			AuthTemplate
		},
		methods: {
			onSubmit() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				reset({email: this.email}).then(() => {
					this.success = true
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {errors: data.errors})
				}).finally(() => this.loading = false)
			}
		}
	}
</script>

<style lang='scss'>
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.pass-recovery {
		max-width: 520px;
		margin: auto;
		&-success {
			max-width: 420px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.app-btn {
					width: 100%;
				}
			}
		}
		&__desc {
			margin: 15px 0 30px;
			@include minw( $grid-breakpoints-sm ) {
				margin: 25px 0 45px;
			}
		}
		.auth-template-form {
			margin: 0;
		}
	}
</style>
